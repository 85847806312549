.extra-buttons {
    visibility: visible;
}

.dashboard-card {
    text-align: left;
}

.dashboard-newbuttons {
    display: none;
}

.dashboard-hide-card {
    display: none
}

.alert-popup {
    position: absolute;
    top: 0;
    /* left: 200; */
    width: 100%;
    right: 0;
}

@media only screen and (max-width: 992px) {
    .dashboard-home {
        text-align: center;
    }

    .dashboard-hide-card {
        display: block
    }

    .extra-buttons {
        display: none;
    }

    /* .dashboard-newcard {
        margin-top: 20px;
    } */

    .dashboard-newbuttons {
        display: block;
    }
    .alert-popup {
        position: absolute;
        top: 0;
        /* left: 200; */
        width: 100%;
        right: 0;
    }
}


@media only screen and (max-width: 768px) {

    .dashboard-newcard {
        margin-top: 20px;
    }

   
}