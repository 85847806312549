#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    /* background: rgba(255, 255, 255, 0.2); */
  }
  
  .logo {
    /* background-color:rgba(215, 191, 53, 0.2);; */
    height: 32px;
    margin: 16px;
  }
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;
  }

  body {
    background: #ffffff4d;
  }